body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: #ffffff;
  background: #000000;
  font-family: "Sora", sans-serif;
}

iframe {
  display: none;
}

.leftShade {
  left: 0;
  right: auto;
  width: 400px;
  top: 0;
  bottom: auto;
  filter: blur(100px);
  height: 600px;
  opacity: 0.55;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    #ff009885 0%,
    #ec00668a 95.78%
  );
}

.rightShade {
  background: linear-gradient(
    93.14deg,
    #ff0098 -20.75%,
    #ff0098 11.84%,
    #ff009870 53.76%,
    #ff009838 102.96%
  );
  opacity: 0.2;
  filter: blur(74px);
  position: absolute;
  right: 0px;
  top: 38%;
  z-index: 999;
  width: 300px;
  height: 300px;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
  // flex-wrap: wrap;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displaySpacearound {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.buttonStyle {
  border-radius: 25px 5px !important;
}

.centerCircle {
  right: auto;
  left: 100px;
  top: 50px;
  width: 150px;
  filter: blur(72px);
  height: 150px;
  opacity: 0.75;
  z-index: -1;
  position: absolute;
  transform: rotate(7deg);
  border-radius: 500px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgb(255, 0, 205, 0.86) 0,
    rgba(255, 0, 205, 0.8196078431) 95.78%
  );
}
.loginLeftSahdow {
  right: auto;
  left: -59px;
  top: -100px;
  width: 600px;
  filter: blur(72px);
  height: 600px;
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  transform: rotate(7deg);
  border-radius: 500px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgb(223 0 255 / 98%) 0,
    rgb(255 0 188 / 65%) 95.78%
  );
}
.loginRightSahdow {
  right: -76px;
  bottom: -244px;
  width: 600px;
  filter: blur(72px);
  height: 600px;
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  transform: rotate(7deg);
  border-radius: 500px;
  background: radial-gradient(
      62.66% 62.66% at 50% 50%,
      #e6e5e8 0%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    #e6e5e8;
}

.loginRightSahdowdash {
  right: -76px;
  bottom: 0px;
  width: 600px;
  filter: blur(72px);
  height: 600px;
  opacity: 0.1;
  z-index: 0;
  position: absolute;
  transform: rotate(7deg);
  border-radius: 500px;
  background: radial-gradient(
      62.66% 62.66% at 50% 50%,
      #e6e5e8 0%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    #e6e5e8;
}

.activeplay {
  background: linear-gradient(
    93.34deg,
    #ff6f37 6.82%,
    #ff2676 35.9%,
    #b801aa 68.08%,
    #7101bc 101.4%
  );
  border-radius: 50px;
}

.disableplay {
  background: rgba(255, 255, 255, 0.05) !important;
  border-radius: 50px;
}

.dazeBox {
  background: rgba(255, 255, 255, 0.02);
  padding: 20px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}

.dazeBox:hover {
  transform: translateY(-5px);
}

.dazeBox::before {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  animation: animate 4s linear infinite;
  background: linear-gradient(
      246.25deg,
      #e6dbdf00 3.73%,
      rgba(247, 56, 137, 0) 29.36%
    ),
    linear-gradient(
      57.35deg,
      #fe4e44 9.51%,
      rgba(255, 0, 152, 0.473658) 23.03%,
      #dadada1a 35.19%
    );
}

// @keyframes animate {
//   0% {
//     transform: rotate(0deg);
//   }

//   100% {
//     transform: rotate(360deg);
//   }
// }
.dazeBox::after {
  content: " ";
  inset: 2px;
  background: #0e0e0e;
  position: absolute;
  border-radius: 12px;
}
.dazeBox1 {
  background: rgba(255, 255, 255, 0.02);
  padding: 20px;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 200px;
    height: 200px;
  }
}

// .dazeBox1:hover {
//   transform: translateY(-5px);
// }

.dazeBox1::before {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: animated 4s linear infinite;
  background: linear-gradient(
    93.14deg,
    #ffb000 -20.75%,
    #ff564d 11.84%,
    #ff0098 53.76%,
    #5d00c1 102.96%
  );
}
.dazeBox1::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: animated 4s linear infinite;
  background: linear-gradient(
    93.14deg,
    #ffb000 -20.75%,
    #ff564d 11.84%,
    #ff0098 53.76%,
    #5d00c1 102.96%
  );
  filter: blur(20px);
}
.dazemidbox {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  background: #0e0e0e;
  border-radius: 50%;
}
@keyframes animated {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rectangularButton {
  border-radius: 10px;
}

.tableCellBackground {
  background: rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(3.07027px);
}

.tabBox {
  padding: 10px;
  cursor: pointer;

  p {
    color: rgba(255, 255, 255, 0.6);
  }
}

.activeTab {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.roundfiled {
  .MuiOutlinedInput-root {
    border-radius: 50px !important;
    background-color: rgba(255, 255, 255, 0.05);
  }

  .MuiIconButton-root {
    background: rgba(255, 255, 255, 0.05);
    padding: 8px;
  }
}

.rightShadeproduct {
  background: linear-gradient(
    93.14deg,
    #ff0098 -20.75%,
    #ff0098 11.84%,
    rgba(255, 0, 152, 0.4392156863) 53.76%,
    rgba(255, 0, 152, 0.2196078431) 102.96%
  );
  opacity: 0.2;
  filter: blur(74px);
  position: absolute;
  right: 0px;
  /* top: 38%; */
  bottom: -29px;
  /* z-index: 999; */
  width: 300px;
  height: 500px;
  z-index: 1;
}

.leftShadeproduct {
  background: linear-gradient(
    93.14deg,
    #ff0098 -20.75%,
    #ff0098 11.84%,
    rgba(255, 0, 152, 0.4392156863) 53.76%,
    rgba(255, 0, 152, 0.2196078431) 102.96%
  );
  opacity: 0.2;
  filter: blur(74px);
  position: absolute;
  left: 0px;
  /* top: 38%; */
  bottom: -29px;
  /* z-index: 999; */
  width: 300px;
  height: 500px;
  z-index: 1;
}
.transparentbutton {
  color: #ffffff !important;
  background-color: #0e0d0e !important;
  // font-family: "Sora", sans-serif !important;
  // font-size: 14px !important;
}
.outlined {
  border: 1px solid #ffffff26 !important;
  color: #ffffff !important;
  background-color: #000 !important;
  // font-family: "Sora", sans-serif !important;
  // font-size: 14px !important;
}
.graybutton {
  color: #fff !important;
  height: 33px !important;
  min-width: 150px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  font-weight: 600;
  border-radius: 8px !important;
  background-clip: padding-box, border-box;
  background: #575361 !important;
  padding: 0px !important;
  height: 40px;
  box-shadow: 0 1px 0 0 #ff00cd, 0 -1px 0 0 #7d00b9, 1px 0 0 0 #f5673f,
    -1px 0 0 0 #f5673f, 1px -1px 0 0 #f5673f, -1px 1px 0 0 #f5673f,
    1px 1px 0 0 #f5673f, -1px -1px 0 0 #f5673f !important;

  // :hover{
  //   background-color: #575361 !important;
  // }
}

.gargButton {
  border: 2px solid !important;
  background: rgba(255, 255, 255, 0.03) !important;
  box-shadow: 0px 0px 0px 3.525px rgba(255, 255, 255, 0.03) !important;
  border-radius: 35.25px;
  border-color: rgba(255, 255, 255, 0.04) !important;
  height: 44px !important;
  color: #fff !important;
  font-family: "Sora" !important;
  font-family: "Sora", sans-serif !important;
  font-size: 13px !important;
}

.borderShadowBox {
  background-image: linear-gradient(black, #000000),
    linear-gradient(152deg, #18181d 49%, rgba(255, 23, 53, 0.7607843137) 75%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: inline-block;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex: 1 1 auto !important;
  padding: 5px !important;
  overflow-y: auto !important;
  padding-top: 5px !important;
  border-radius: 20px !important;
}
.selectpaper {
  .MuiMenu-list {
    background: #2e2e2f !important;
  }
}
.sideMenuBox {
  overflow: auto;
  height: calc(100vh - 160px);
  @media (max-width: 600px) {
    height: calc(100vh - 33px);
  }
}
.sideMenuBox::-webkit-scrollbar {
  display: none;
}
.neontext {
  box-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #bc13fe,
    0 0 82px #bc13fe, 0 0 92px #bc13fe, 0 0 102px #bc13fe, 0 0 151px #bc13fe;
}
.topslickslider {
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 50%;
    background: 0 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    left: auto !important;
    bottom: -20px !important;
    top: -163px !important;
    right: 60px !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #000 !important;
    border: 2px solid #323232;

    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #ff6243, #ff0594, #8b00b5) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    top: -163px !important;
    bottom: -20px !important;
    left: auto !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #000 !important;
    border: 2px solid #323232;
    z-index: 999 !important;
    &:hover {
      background: #000;
      border: solid 2px transparent !important;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #ff6243, #ff0594, #8b00b5) !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #050706 inset !important;
      border-radius: 50px !important;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }
}
.topslickslider1 {
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 50%;
    background: 0 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    top: 58%;
    bottom: -20px !important;
    right: auto !important;
    left: -42px !important;
    color: #fff !important;
    z-index: 999 !important;
    background: linear-gradient(
      152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(21px);

    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    top: 58%;
    bottom: -20px !important;
    left: auto !important;
    right: -42px !important;
    color: #fff !important;
    background: linear-gradient(
      152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 0px 53px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(21px);
    z-index: 999 !important;

    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }
}
.countBox {
  background: rgba(255, 255, 255, 0.1);
  width: 65px;
  height: 39px;
  display: flex;
  /* padding: 10px 0; */
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}
.trimText {
  white-space: nowrap;
  width: 164px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.borderBox {
  background-color: rgba(255, 255, 255, 0.12) !important;
  margin: 19px 0 !important;
}

.iconBackgroundColor {
  background-color: "rgba(255, 255, 255, 0.025)";
}
.webTableStyle {
  .MuiTableHead-root {
    background: transparent !important;
  }
  .MuiTable-root {
    background: transparent !important;
  }
  .MuiTableRow-root th:nth-child(odd) {
    background: transparent !important;
  }
  .MuiTableRow-root td:nth-child(odd) {
    background: transparent !important;
  }
  .MuiTableRow-root th {
    padding: 15px 8px !important;
  }
  .MuiTableRow-root td {
    padding: 15px 8px !important;
  }
}

// .newModalBorder:before {
//   .newModalBorder>.mainbox1 {
//     width: calc(100% - 6px);
//     height: calc(100% - 4px);
//     margin: auto;
//     position: relative;
//     overflow: hidden;
// }
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(315deg,#9607e0,#ff0021);
// }
// .newModalBorder>.mainbox1:before {
//   content: "";
//   position: absolute;
//   top: 3px;
//   left: 0;
//   width: 100%;
//   height: calc(100% - 6px);
//   background: #090901 !important;
//   border-radius: 15px;
// }
// .mainbox1 {
//   width: calc(100% - 6px);
//     height: calc(100% - 4px);
//     margin: auto;
//     position: relative;
//     overflow: hidden;
// }
.newModalBorder {
  background: linear-gradient(
    312.78deg,
    #ffb000 -25.59%,
    #ff564d 12.16%,
    #ff0098 39.51%,
    #5d00c1 55.74%
  );
  border-radius: 10px;
  padding: 2px !important;
}
.mainbox1 {
  background: #0a0a01;
  border-radius: 10px;
  padding: 16px;
}

.gradientfirexText {
  background: linear-gradient(
    93.34deg,
    #ff6f37 6.82%,
    #ff2676 35.9%,
    #b801aa 68.08%,
    #7101bc 101.4%
  );
  text-shadow: 3px 3px 3px rgb(0 0 0 / 13%);
  -webkit-background-clip: text;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 77%);
  -webkit-tap-highlight-color: transparent;
}

.hoveredText {
  // background-color: rgba(0, 10, 37, 1);
  // border: 2px solid #fff;
  // border-radius: 7px;
  // box-shadow: -1px 1px 11px rgba(255, 255, 255, 1);
  // min-height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // max-width: 780px;
  padding: 10px;
  text-align: center;
  margin: 0 auto 20px;
  .mapIcon {
    max-width: 50px;
    margin-bottom: 8px;
  }
  p {
    color: #fff;
    line-height: 23px;
  }
  h5 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.mapImgHolder {
  text-align: center;
  width: 100%;
  overflow: hidden;
  // overflow-x: auto;
  .mapImg {
    transition: opacity 1s ease-in-out;
    text-align: center;
    width: 800px;
    position: relative;
    margin: 0 auto;
    .mainImg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    img {
      width: 800px;
      margin: 0 auto;
      transition: opacity 1s ease-in-out;
    }
    .mapimglist {
      position: absolute;
      .hoverdBox {
        display: none;
      }
      &:hover {
        transition: opacity 1s ease-in-out;
        .hoverdBox {
          transition: opacity 1s ease-in-out;
          .mapIcon {
            width: 40px;
          }
          transition: opacity 1s ease-in-out;
          display: block;
          background-color: rgba(255, 255, 255, 0.75);
          padding: 20px;
          border-radius: 0.75rem;
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          min-width: 150px;
          p {
            color: #000;
          }
        }
      }
    }
    .mapImg1 {
      transition: opacity 1s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      width: 425px;
      height: 220px;
    }
    .mapImg2 {
      transition: opacity 1s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      width: 441px;
      height: 206px;
    }
    .mapImg3 {
      transition: opacity 1s ease-in-out;
      position: absolute;
      top: 178px;
      right: 207px;
      width: 346px;
      height: 203px;
    }
    .mapImg4 {
      position: absolute;
      top: 178px;
      right: -1px;
      width: 195px;
      height: 168px;
    }
    .mapImg5 {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 434px;
      height: 242px;
    }
    .mapImg6 {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 335px;
      height: 256px;
    }
  }
}
.textrightBox {
}
.textContent {
  padding: 40px 10px;
  text-align: center;
  border-image: linear-gradient(
    93.14deg,
    #ffb000 -20.75%,
    #ff564d 11.84%,
    #ff0098 53.76%,
    #5d00c1 102.96%
  );
  border-style: solid;
  border-width: 2px;
  border-image-slice: 9;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flexorder1 {
  order: 1;
}
.flexorder2 {
  order: 2;
}
.MuiPaginationItem-page.Mui-selected {
  background: linear-gradient(
    93.14deg,
    #ffb000 -20.75%,
    #ff564d 11.84%,
    #ff0098 53.76%,
    #5d00c1 102.96%
  );
}

.textOverflowEllipsis {
  white-space: nowrap;
  width: 165px;
  overflow: hidden;
  text-overflow: ellipsis;
}
iframe {
  pointer-events: none;
}
.moveTop {
  animation: movetop 5s linear;
}
@keyframes movetop {
  from {
    opacity: 0;
    bottom: 20px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
}
::-webkit-scrollbar {
  display: none;
}
