@media (max-width:1440px){
   
}
@media (max-width:1280px){
   
}
@media (max-width:1024px){
    .flexorder1{
        order: 2;
    }
    .flexorder2{
        order: 1;
    }
   
}
@media (max-width:991px){
   
}
@media (max-width:767px){
   .mapImgHolder{
    overflow-x: auto;
   }
}